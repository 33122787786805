//react libraries import
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';


//custom components/libraries import
import Gallery from '../components/gallery';

const GalleryPage = () => {

    const medias = useStaticQuery(
        graphql`
          query {
            photos: allFile(filter: {relativePath: {regex: "/gallery/"}}) {
              nodes {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, quality: 100, height: 250, width: 400)
                }
              }
            }
          }      
        `
    )

    
    return(
        <main>
            <div className="w-full pt-12 pb-12 px-8 bg-white">
                <div className="text-center font-bold text-primary text-2xl mb-6">
                    Gallery
                </div>
                <Gallery medias={medias.photos.nodes}/>
            </div>
        </main>
    );
}

export default GalleryPage;